import React from "react"
import Metadata from "../components/Metadata/Metadata"
import LayoutAbout from "../layout/LayoutAbout"
import Heading from "../components/Heading/Heading"

const Main = () => (
    <p>
        Unfortunately, the page you've navigated to does not exist. Please use
        the top menu navigation options to find your way back to content we know
        exists, or click <a href="/">here</a> to return back to the home page.
    </p>
)

const NotFoundPage = () => (
    <>
        <Metadata title="404 Error (Page not found)" />
        <LayoutAbout
            heading={
                <Heading
                    headingTitle="404 - Error"
                    headingDescription="Page not found"
                />
            }
            main={<Main />}
        />
    </>
)

export default NotFoundPage
