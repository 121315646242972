import React from "react"
import { motion } from "framer-motion"
import "./Layout.scss"
import { variantsSecondary, variantsTertiary } from "../helpers/motionVariants"

export default props => (
    <article className="layout">
        {props.heading ? props.heading : null}

        <section className="layout__grid">
            {props.main || props.aside ? (
                <section className="layout__grid____content">
                    {props.main ? (
                        <motion.main
                            className="layout__grid____primary rich_text"
                            variants={variantsSecondary}
                            initial="hidden"
                            animate="enter"
                        >
                            {props.main}
                        </motion.main>
                    ) : null}

                    {props.aside ? (
                        <motion.aside
                            className="layout__grid____secondary"
                            variants={variantsTertiary}
                            initial="hidden"
                            animate="enter"
                        >
                            {props.aside}
                        </motion.aside>
                    ) : null}
                </section>
            ) : null}
        </section>
    </article>
)
